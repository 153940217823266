/**
 * @file index.css
 * @description This file contains the global styles for the application.
 */

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("./fonts/Roboto-Regular.ttf");
}

@font-face {
  font-family: "Icons";
  src: url("./fonts/Icons.woff");
}

:root {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #333;
}

::selection {
  text-shadow: none;
  color: #fff;
  background: #0794d9;
}

::-webkit-scrollbar {
  background: #3a3a3a33;
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #0794d9;
  width: 10px;
  height: 10px;
}

body {
  overflow-y: scroll;
}

li::marker {
  font-size: 1rem;
}

.flg span {
  display: inline-block !important;
}

.flg span::first-letter {
  color: #0794d9;
}

.flg em {
  font-style: normal;
  color: #0794d9;
}

.no-scroll {
  overflow: hidden;
}

:disabled {
  cursor: not-allowed;
  filter: grayscale(0.75);
}

@layer components {
  .itemHeader {
    @apply relative h-full items-center flex;
  }

  .itemHeader.active::before,
  .itemHeader.active:hover::after {
    content: "";
    @apply bottom-[5px] left-[0] absolute bg-[#0794d9] w-[10px] h-[10px] rounded-[50%];
  }

  .itemHeader:hover::after {
    content: "";
    @apply bottom-[5px] left-[0] absolute w-[10px] h-[10px] rounded-[50%] border-[#0794d9] bg-[#fff] border-[2px];
  }

  .input {
    @apply min-h-[50px] bg-[#fff] border-[1px] border-[#ccc] text-[#3a4a56] text-[1.1rem] px-[15px] rounded-[6px] outline-none focus:border-[#0794d9];
  }

  .contactCard {
    @apply bg-[#f3f3f3] min-h-[100px] md:grid md:grid-cols-[120px_1fr] flex justify-center items-center flex-col text-[1.3rem] py-[20px] rounded-[16px] shadow-[0_16px_10px_0_rgba(0,0,0,.1)] sm:text-[1.1rem] w-full max-w-[600px];
  }

  .contactCard:nth-child(2n) {
    @apply justify-self-start;
  }

  .contactCard:nth-child(2n + 1) {
    @apply justify-self-end;
  }

  .icon {
    @apply hover:text-[#0794d9];
  }

  .icon.i-tvmux::before {
    content: "";
  }

  .icon.i-ipmux::before {
    content: "";
  }

  .icon.i-infobox::before {
    content: "";
  }

  .icon.i-cablebox::before {
    content: "";
  }

  .icon.i-vnubebox::before {
    content: "";
  }

  .icon.i-mini::before {
    @apply text-[6rem] p-[30px];
  }

  .icon::before {
    content: "";
    font-family: "Icons";
    font-style: normal;
    @apply text-[8rem] p-[50px] border-[#ccd1d8] border-[4px] rounded-[50%];
  }

  .check:nth-child(2n + 1) {
    @apply border-r-[1px] border-[#ccc] xs:border-none;
  }

  .check > p:first-of-type {
    @apply inline;
  }
}
